<template>
  <a class="GalleryFile" @click="$emit('click', item)" :file="`file${item._id}`" :style="`--image:url(${src})`" :key="item._id">
    <IntrinsicSVG size="293" />
  </a>
</template>

<script>
export default {
  props: ["item"],
  computed: {
    src: function() {
      if (this.item.thumbnail && this.item.thumbnail.src) {
        return this.item.thumbnail.src;
      }

      if (this.item.file.src) {
        return this.item.file.src;
      }
    },
  },
};
</script>
